<template>
    <div class="opinion">
        <full-scroll></full-scroll>
        <div
            class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            "
        >
            <div class="flex flex-pack-center flex-1">
                <img
                    src="@/assets/national_emblem.png"
                    alt=""
                    class="msg-national m_r20"
                />
                <div class="text_align_last">
                    <p class="font-size24 m_b10">广阳镇人大代表之家</p>
                    <p class="font-size12">
                        GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
                    </p>
                </div>
            </div>
            <div class="msg-title-mid-box font-size32 font-blod flex-1">
                <img src="@/assets/images/tot-top-title.png" alt="" />
                <div class="msg-title-mid-box-title">展示台</div>
            </div>
            <div class="flex-1 flex-end flex" @click="backHome">
                <div class="my_text_center">
                    <img
                        src="@/assets/images/backhome-new.png"
                        alt=""
                        class="msg-backhome m_b10"
                    />
                    <p>返回首页</p>
                </div>
            </div>
        </div>

        <div class="msg-mid flex flex-v flex-justify-center flex-pack-center">
            <div class="feedback-box flex flex-justify-between">
                <div class="feedback-left">
                    <p
                        class="
                            font-size28 font-blod
                            feedback-left-tot-title
                            color_FF
                            my_text_center
                            m_b10
                        "
                    >
                        民意窗
                    </p>
                    <div class="font-size24 feedback-left-tot-box">
                        <ul>
                            <li
                                class="
                                    feedback-list
                                    flex flex-justify-between flex-pack-center
                                    font-blod font-size18
                                "
                                :class="isActived == index ? 'actived' : ''"
                                v-for="(item, index) in superviseType"
                                :key="index"
                                @click="changeSuperviseType(index)"
                            >
                                <div class="flex flex-pack-center">
                                    <div
                                        class="feedback-list-oricle"
                                        :class="
                                            isActived == index
                                                ? ''
                                                : 'hideFeeback'
                                        "
                                    ></div>
                                    {{ item.label }}
                                </div>
                                <div
                                    v-if="isActived == index"
                                    :class="
                                        isActived == index ? '' : 'hideFeeback'
                                    "
                                >
                                    >
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="feedback-right">
                    <div class="feedback-right-box">
                        <ul>
                            <van-list
                                v-model="loading"
                                :finished="finished"
                                finished-text="没有更多了"
                                @load="onLoad"
                            >
                                <li
                                    class="
                                        font-size24
                                        m_b5
                                        feedback-right-list
                                        flex
                                        flex-justify-between
                                        flex-pack-center
                                    "
                                    v-for="(item, id) in superviseList"
                                    :key="id"
                                >
                                    <div class="flex color_FF">
                                        <div class="m_r20">
                                            <img
                                                :src="
                                                    item.type == 1
                                                        ? ccVideo
                                                        : item.type == 2
                                                        ? ccWord
                                                        : item.type == 3
                                                        ? ccAudio
                                                        : ''
                                                "
                                                alt=""
                                                class=""
                                            />
                                        </div>
                                        <div
                                            class="
                                                flex flex-v flex-justify-center
                                            "
                                            style="width: 36vw"
                                        >
                                            <div
                                                class="
                                                    flex
                                                    flex-justify-between
                                                    flex-pack-center
                                                "
                                                style="width: 100%"
                                            >
                                                <div class="flex">
                                                    <p
                                                        class="
                                                            font-size18
                                                            m_b10 m_r20
                                                        "
                                                    >
                                                        反馈人：{{
                                                            item.realName
                                                        }}
                                                    </p>
                                                    <p
                                                        class="
                                                            font-size18
                                                            m_b10 m_r20
                                                        "
                                                    >
                                                        联系电话：{{
                                                            item.contactNum
                                                        }}
                                                    </p>
                                                    <p
                                                        class="
                                                            font-size18
                                                            m_b10 m_r20
                                                        "
                                                    >
                                                        反馈时间：{{
                                                            item.createTime
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="font-size18 m_r20"
                                                :class="
                                                    item.status == 1
                                                        ? ''
                                                        : 'm_b30'
                                                "
                                            >
                                                <div class="font-size18 m_r20">
                                                    状态：
                                                    <van-tag
                                                        :type="
                                                            item.status == 1
                                                                ? 'warning'
                                                                : 'primary'
                                                        "
                                                        >{{
                                                            item.status == 1
                                                                ? "未处理"
                                                                : "已处理"
                                                        }}</van-tag
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                class="font-size18 m_b10"
                                                v-if="item.status == 2"
                                            >
                                                <div class="flex">
                                                    <p
                                                        class="
                                                            font-size18
                                                            m_b10 m_r20
                                                        "
                                                    >
                                                        处理人：{{
                                                            item.delegateName
                                                        }}
                                                    </p>
                                                    <p
                                                        class="
                                                            font-size18
                                                            m_b10 m_r20
                                                        "
                                                    >
                                                        处理时间：{{
                                                            item.handleTime
                                                        }}
                                                    </p>
                                                </div>
                                                <p>
                                                    评价星级：<van-rate
                                                        v-model="
                                                            item.appraisalLevel
                                                        "
                                                        allow-half
                                                        readonly
                                                        void-icon="star"
                                                        void-color="#eee"
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="
                                            color_FF
                                            font-blod font-size18
                                            supervise-check
                                        "
                                        @click="goToDetail(item)"
                                    >
                                        查看详情
                                    </div>
                                </li>
                            </van-list>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            "
        >
            <div class="my_text_center" @click="goBack">
                <img
                    src="@/assets/images/return-new.png"
                    alt=""
                    class="msg-return m_b10"
                />
                <p class="font-size24 color_FF">返回上级</p>
            </div>
            <div class="font-blod my_text_center color_FF">
            </div>

            <div class="my_text_center color_FF">
                <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
                <p class="font-size14">{{ todayTime }}</p>
            </div>
        </div>

        <!--反馈详情 start-->
        <div class="opinion-hide" v-if="isShowHide">
            <div class="opinion-hide-box">
                <div class="font-size22 m_b5">
                    <p class="m_b10">反馈人：{{ opinion.realName }}</p>
                    <p class="m_b10">反馈时间：{{ opinion.createTime }}</p>
                    <p class="m_b10">反馈内容：</p>
                    <div class="m_b30">
                        <video
                            :src="opinion.file"
                            controls
                            v-if="opinion.type == 1"
                            height="300px"
                            width="300px"
                        ></video>
                        <audio
                            :src="opinion.file"
                            controls
                            v-if="opinion.type == 3"
                        ></audio>
                        <p v-if="opinion.type == 2">{{ opinion.content }}</p>
                    </div>
                    <p class="m_b10">
                        是否处理：{{
                            opinion.status == 1 ? "未处理" : "已处理"
                        }}
                    </p>
                    <div v-if="opinion.status == 2">
                        <p class="m_b10">处理人：{{ opinion.delegateName }}</p>
                        <p class="m_b10">处理时间：{{ opinion.handleTime }}</p>
                        <p class="m_b10">处理结果：{{ opinion.result }}</p>
                        <p class="m_b10">
                            评价星级：<van-rate
                                v-model="opinion.appraisalLevel"
                                allow-half
                                readonly
                                void-icon="star"
                                void-color="#eee"
                            />
                        </p>
                    </div>
                </div>
                <div class="opinion-hide-sure my_text_center">
                    <span @click="cancalHide">确定</span>
                </div>
            </div>
        </div>
        <!--反馈详情 end-->
    </div>
</template>

<script>
import { feedbackPublicPage } from "@/request/api.js";
import { dateTimeFormat } from "@/request/until.js";
import fullScroll from "@/components/fullScroll.vue";

export default {
    components: { fullScroll },
    data() {
        return {
            todayMtime: "",
            todayTime: "",
            value: 3,
            isActived: 0,
            pageNum: 1,
            pageSize: 6,
            totalPage: null,
            loading: false,
            status: 0, //是否处理
            superviseList: [],
            isShowHide: false,
            finished: false,
            ccVideo: require("@/assets/images/cc-video.png"),
            ccWord: require("@/assets/images/cc-word.png"),
            ccAudio: require("@/assets/images/cc-audio.png"),
            superviseType: [
                {
                    id: 1,
                    label: "全部",
                },
                {
                    id: 2,
                    label: "已处理",
                },
                {
                    id: 3,
                    label: "未处理",
                },
            ],
            opinion: null,
        };
    },
    created() {
        this.getTime();
    },
    mounted() {
        this.getFetch();
        this.timer = setInterval(() => {
            this.getTime();
        }, 1000);
    },

    methods: {
        getTime() {
            let _this = this;
            let time = "";
            time = dateTimeFormat(new Date());
            _this.todayTime = time.split(" ")[0];
            _this.todayMtime = time.split(" ")[1].slice(0, 5);
        },

        changeSuperviseType(index) {
            this.isActived = index;
            this.superviseList = [];
            switch (index) {
                case 0:
                    this.status = 0;
                    this.pageNum = 1;
                    this.getFetch();
                    break;
                case 1:
                    this.status = 2;
                    this.pageNum = 1;
                    this.getFetch();
                    break;
                case 2:
                    this.status = 1;
                    this.pageNum = 1;
                    this.getFetch();
                    break;
            }
        },
        getFetch() {
            feedbackPublicPage({
                status: this.status,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }).then((res) => {
                this.loading = false;
                if (res.code == 200) {
                    let data = res.data.list;
                    if (data.length == 0) {
                        this.finished = true;
                    } else {
                        if (this.pageNum == res.data.totalPage) {
                            this.finished = true;
                        } else {
                            this.finished = false;
                        }

                        data.forEach((item) => {
                            item.createTime = item.createTime.split(" ")[0];
                            item.appraisalLevel = Number(item.appraisalLevel);
                            item.handleTime =
                                item.handleTime == null
                                    ? ""
                                    : item.handleTime.split(" ")[0];
                        });
                        this.totalPage = res.data.totalPage;
                        this.superviseList.push(...data);
                    }
                }
            });
        },

        onLoad() {
            if (this.totalPage <= this.pageNum) {
                this.loading = false;
                this.finished = true;
            } else {
                this.loading = true;
                this.pageNum++;
                this.getFetch();
            }
        },

        goToDetail(data) {
            this.isShowHide = true;
            this.opinion = data;
        },

        cancalHide() {
            this.isShowHide = false;
            this.opinion = null;
        },

        //返回首页
        backHome() {
            this.$router.push("/");
        },
        goBack() {
            this.$router.back();
        },
    },
};
</script>
